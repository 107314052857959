import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import useTranslations from "../components/useTranslations";
import LocalizedLink from "../components/LocalizedLink";
import Seo from "../components/seo";
import config from "../../config/i18n";

const PageNotFound = ({ path, location }) => {
  const { translate } = useStaticQuery(query);
  const { page_not_found_headline, page_not_found_info, return_homepage } =
    useTranslations(translate);

  useEffect(() => {
    Object.keys(config).map((lang) => {
      if (location.pathname.startsWith(`/${config[lang].path}/`)) {
        return navigate(`/${config[lang].path}/404`, { replace: false });
      }
      return null;
    });
  }, [location.pathname]);

  return (
    <>
      <Seo title="404" description={page_not_found_headline} pathname={path} />
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-12">
            <h1 className="headline_page_not_found">404</h1>
          </div>
          <div className="col-12">
            <h2>{page_not_found_headline.toUpperCase()}</h2>
          </div>
          <div className="col-12">
            <p>{page_not_found_info}</p>
          </div>
          <div className="col-12">
            <LocalizedLink
              to="/"
              aria-label="homepage"
              className={`btn btn-style-1-small`}
            >
              <span className="f-light">{return_homepage}</span>
            </LocalizedLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;

const query = graphql`
  query PageNotFound {
    translate: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            page_not_found_headline
            page_not_found_info
            return_homepage
          }
        }
      }
    }
  }
`;
